

import DxPieChart, {
  DxSize,
  DxLabel,
  DxConnector,
  DxExport,
} from 'devextreme-vue/pie-chart';
import DxChart, {
  DxArgumentAxis,
  DxValueAxis,
  DxLegend,
  DxTick,
  DxSeries,
  DxPoint,
  DxGrid,
  DxTooltip,
  DxCommonPaneSettings,
  DxBorder,
} from 'devextreme-vue/chart';
import {
  DxCircularGauge, DxScale, DxGeometry, DxRangeContainer, DxRange, DxSubvalueIndicator,
} from 'devextreme-vue/circular-gauge';
import { DxTooltip as DxTooltipVue } from 'devextreme-vue/tooltip';
import DxSelectBox from 'devextreme-vue/select-box';
import DxDateBox from 'devextreme-vue/date-box';
import DxButton from 'devextreme-vue/button';
import { DxDataGrid, DxSearchPanel, DxPager, DxPaging } from 'devextreme-vue/data-grid';
import auth from '../auth';

import IndicatorService from '../services/indicator-service';
import CompanyService from '../services/company-service';
import ClientService from '../services/client-service';
import KpiService from '../services/kpi-service';

export default {
  components: {
    DxPieChart,
    DxSize,
    DxSeries,
    DxLabel,
    DxConnector,
    DxExport,
    DxCircularGauge,
    DxScale,
    DxGeometry,
    DxSelectBox,
    DxDateBox,
    DxButton,
    DxChart,
    DxArgumentAxis,
    DxValueAxis,
    DxLegend,
    DxTick,
    DxRangeContainer,
    DxRange,
    DxSubvalueIndicator,
    DxPoint,
    DxGrid,
    DxTooltip,
    DxCommonPaneSettings,
    DxBorder,
    DxDataGrid,
    DxTooltipVue,
    DxSearchPanel,
    DxPager,
    DxPaging,
  },
  data() {
    const now = new Date();
    return {
      resolveMode: 'shift',
      usuarioId: null,
      company: null,
      idCliente: 0,
      columns: ['nombre', 'valor'],
      client: null,
      startDate: new Date(now.getFullYear() - 1, now.getMonth(), 1),
      endDate: new Date(now.getFullYear(), now.getMonth() + 1, 0),
      dataSourceCompanies: null,
      dataSourceClients: null,
      dataSourceWrenchTime: null,
      dataSourceWrenchTimePercentage: null,
      dataSourceWorkOrderStatus: null,
      dataSourceWorkOrderOrigin: null,
      dataSourceWorkOrderMaintenanceTypes: null,
      dataSourceWorkOrderUserGroups: null,
      dataSourceWorkOrdersByClient: null,
      dataSourceWorkOrdersDone: null,
      dataSourceWorkOrderCancels: null,
      dataSourceWorkOrderTaskCancels: null,
      dataSourceMaintenancePolicies: null,
      dataSourceTaskDoneOrNot: null,
      dataSourceMeasurementDoneOrNot: null,
      dataSourceFaultReportStatus: null,
      dataSourceFaultReportTypes: null,
      dataSourceAlertSeverity: null,
      dataSourceGeofenceEquipment: null,
      workOrderClosedOnTimePercentage: 0,
      workOrderCompliance: 0,
      workOrderPMP: 0,
      activeUsers: 0,
      activeWorkOrders: 0,
      workOrdersDone: 0,
      checklists: 0,
      faultReports: 0,
      alerts: 0,
      isValidateRol: true,
      datakpi: null,
    };
  },
  created() {
    this.clientService = new ClientService();
    this.indicatorService = new IndicatorService();
    this.companyService = new CompanyService();
    this.kpiService = new KpiService();
    const usuario = JSON.parse(localStorage.getItem("user"));
    if (usuario.id_rol == 9) {
      this.isValidateRol = false;
    }
  },
  mounted() {
    this.clientService.getClienteComboPorUsuarioCombo().then(data => {
      this.dataSourceClients = data;
      this.client = data[0];
    });
    auth.getUser().then((data) => {
      this.usuarioId = data.data.id_usuario;
      this.companyService.getEmpresas().then((data) => {
        this.dataSourceCompanies = data;
        this.company = data[0];

        if (this.company != null && this.startDate != null && this.endDate != null) {
          this.kpiService.getdashboardweb(this.company.id_empresa, 0, this.startDate, this.endDate, this.usuarioId, 1).then(data => {
            this.datakpi = data[0].kpis;
          });
        }
      });
    });
  },
  methods: {
    formatLabel(pointInfo) {
      return `${pointInfo.valueText} (${pointInfo.percentText})`;
    },
    customizeText({ valueText }) {
      return `${valueText} %`;
    },
    customizeLabelText({ valueText }) {
      return `${valueText}`;
    },
    onClick() {

      if (this.company != null && this.startDate != null && this.endDate != null) {
        if (this.client == null) {
          this.idCliente = 0;
        } else {
          this.idCliente = this.client.id_cliente;
        }
        this.kpiService.getdashboardweb(this.company.id_empresa, this.idCliente, this.startDate, this.endDate, this.usuarioId, 1).then(data => {
          this.datakpi = data[0].kpis;
        });
      }
    },
    pointClickHandler(e) {
      this.toggleVisibility(e.target);
    },
    legendClickHandler(e) {
      const arg = e.target;
      const item = e.component.getAllSeries()[0].getPointsByArg(arg)[0];

      this.toggleVisibility(item);
    },
    toggleVisibility(item) {
      item.isVisible() ? item.hide() : item.show();
    },
    valueChangedCompanies(e) {
      console.log("valueChangedCompanies", e.value);
    }
  },
};
